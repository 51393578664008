<template>
  <main class="centerPage text-center">
    <h2>Welcome to</h2>
    <h1>AlisonSmith-me</h1>
    <h4>A personal Blog/Test Platform for my projects...</h4>

    <button class="signRegBtn mt-5" @click="openModal">
      Click to Sign In (Register)
    </button>
  </main>

  <teleport to="body">
    <RegisterForm v-if="isModalOpen" />
  </teleport>
</template>

<script>
import RegisterForm from "@/components/RegisterForm.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "HomePage",
  components: {
    RegisterForm,
  },

  computed: {
    ...mapGetters({
      isUserAuth: "getIsUserAuth",
      isModalOpen: "getIsModalOpen",
    }),
  },
  methods: {
    ...mapActions(["toggleModal"]),

    openModal() {
      this.toggleModal();
    },
  },
};
</script>

<style lang="scss">
.signRegBtn {
  font-size: 1.2em;
  padding: 0.25em 1.75em;
  border-radius: 100px;
  font-style: italic;
  background-color: transparent;
  color: white;
  border: 2px solid #fff;
  align-self: center;
  @media only screen and (min-width: 768px) {
    font-size: 1.5em;
  }
   @media only screen and (min-width: 976px) {
    font-size: 1.8em;
  }
   @media only screen and (min-width: 1200px) {
    font-size: 2.2em;
  }
   @media only screen and (min-width: 1400px) {
    font-size: 2.9em;
  }
}
</style>
