<template>
  <div class="window">
    <NavBar />

    <div class="">
      <img src="./assets/img/space-generic-1.jpg" alt="" />
      <router-view />
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";



export default {
  name: "app",
  components: { NavBar },
};
</script>

<style>
/* body {
  background: url("./assets/img/space-generic-1.jpg") no-repeat 0 0 / auto;
} */
img {
  /* position: fixed; */
  z-index: -100;
}
/* .window {
  height: 200vh;
} */

a {
  text-decoration: none !important;
}

a.active,
a.exact-active {
  text-decoration: underline !important;
}

.centerPage {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  border-radius: 4px;
}
</style>
